<template>
	<div class="vld-parent">
	<loading :active="loading" color="#D9F7C5" loader="bars"/>
	</div>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="xyz">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:right>
						<Button label="Nuevo" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
					</template>
				</Toolbar>
				<DataTable ref="dt" :value="plantillas" class="p-datatable-sm" v-model:selection="selected" dataKey="id_mov_plantilla" :paginator="true" :rows="10" :filters="filters"
							:globalFilterFields="['plantilla']" 
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords}" :loading="loadingGrid"
							:key="plantillasGrdRefresh">
					<template #header>
						<div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
							<h5 class="p-m-0">{{ titulo }}</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="plantilla" :header="columnaTitulo" :sortable="true"/>
					<Column field="total" header="Monto" :sortable="true" headerClass="p-d-flex p-jc-end p-mr-2">
						<template #body="slotProps">
							<div v-if="slotProps.data.es_variable == 0" class="p-d-flex p-jc-end p-mr-2" sytle="font-weight: bold;">
								<div>{{ formatCurrencyMoneda(slotProps.data.id_moneda, slotProps.data.total) }}</div>
							</div>
							<div v-else class="p-d-flex p-jc-end p-mr-2" sytle="font-weight: bold;">
									(Variable)
							</div>				
						</template>								
					</Column>				
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="edit(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDelete(slotProps.data)" />
						</template>
					</Column>
				</DataTable>
				
				<Dialog v-model:visible="nuevoDialog" :style="{width: '450px'}" header="Nuevo" :modal="true">
					<div class="p-field">
						<div class="p-inputgroup p-m-1">
							<label class="p-mr-1 p-as-center" for="plantilla">{{ columnaTitulo }} </label>
							<InputText id="plantilla" v-model="plantilla.plantilla" :maxlength="100"  required="true" autofocus :class="{'p-invalid': submitted && !plantilla.plantilla}" />
							<small class="p-invalid" v-if="submitted && !lista.plantilla">Este información es requerida</small>
						</div>
					</div>
					<div class="p-field">
						<div class="p-inputgroup p-m-1">
							<label class="p-mr-1 p-as-center" for="tipo">Tipo</label>
                            <Dropdown v-model="plantilla.id_movimiento_tipo" :options="lkpMovimientosTipo" optionLabel="movimiento_tipo" @change="cargarSeries" optionValue="id_movimiento_tipo" placeholder="Seleccionar..." />                            
						</div>
					</div>      
					<div class="p-field">
						<div class="p-inputgroup p-m-1">
							<label class="p-mr-1 p-as-center" for="serie">Serie</label>
                            <Dropdown v-model="plantilla.id_movimiento_serie" :options="lkpSeries" optionLabel="serie" optionValue="id_movimiento_serie" placeholder="Seleccionar..." />                            
						</div>
					</div>           
					<div class="p-field">
						<div class="p-inputgroup p-m-1">
							<label class="p-mr-1 p-as-center" for="condicion_pago">Condición de Pago</label>
                            <Dropdown v-model="plantilla.id_condicion_pago" :options="lkpCondicionesPago" optionLabel="condicion_pago" optionValue="id_condicion_pago" placeholder="Seleccionar..." />                            
						</div>
					</div>       
					<div class="p-field">
						<div class="p-inputgroup p-m-1">
							<label class="p-mr-1 p-as-center" for="moneda">Moneda</label>
                            <Dropdown v-model="plantilla.id_moneda" :options="lkpMonedas" optionLabel="moneda" optionValue="id_moneda" placeholder="Seleccionar..." />                            
						</div>
					</div>   
					<div class="p-field">
						<div class="p-inputgroup p-m-1">
							<label class="p-mr-1 p-as-center" for="plantilla_notif">Plantilla de Notificación</label>
                            <Dropdown v-model="plantilla.id_notificacion_plantilla" :options="lkpPlantillasNotif" optionLabel="plantilla" optionValue="id_notificacion_plantilla" placeholder="Seleccionar..." />                            
						</div>
					</div>                       
					<div class="p-field">
						<div class="p-inputgroup p-m-1">
							<label class="p-mr-1 p-as-center" for="referencia">Referencia</label>
							<InputText id="plantilla" v-model="plantilla.referencia" :maxlength="100" />							
						</div>
					</div>                                                                                                                
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="nuevoDialog=false"/>
						<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="guardarRec" />
					</template>
				</Dialog>
 
				<Dialog v-model:visible="plantillaDialog" :style="{width: '850px'}" :header="columnaTitulo" :modal="true" class="p-fluid">
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col-12">
							<label class="p-mr-1 p-as-center" for="plantilla">{{ columnaTitulo }} </label>
							<InputText id="plantilla" v-model="plantilla.plantilla" :maxlength="100"  required="true" autofocus :class="{'p-invalid': submitted && !plantilla.plantilla}" />
							<small class="p-invalid" v-if="submitted && !plantilla.plantilla">Este información es requerida</small>
						</div>
						<div class="p-field p-col-6">
							<label class="p-mr-1 p-as-center" for="tipo">Tipo</label>
							<Dropdown v-model="plantilla.id_movimiento_tipo" :options="lkpMovimientosTipo" optionLabel="movimiento_tipo" @change="cargarSeries" optionValue="id_movimiento_tipo" placeholder="Seleccionar..." />
						</div>      
						<div class="p-field p-col-6">
							<label class="p-mr-1 p-as-center" for="serie">Serie</label>
							<Dropdown v-model="plantilla.id_movimiento_serie" :options="lkpSeries" optionLabel="serie" optionValue="id_movimiento_serie" placeholder="Seleccionar..." />                            
						</div>
						<div class="p-field p-col-6">
							<label class="p-mr-1 p-as-center" for="condicion_pago">Condición de Pago</label>
							<Dropdown v-model="plantilla.id_condicion_pago" :options="lkpCondicionesPago" optionLabel="condicion_pago" optionValue="id_condicion_pago" placeholder="Seleccionar..." />                            
						</div>       
						<div class="p-field p-col-6">
							<label class="p-mr-1 p-as-center" for="moneda">Moneda</label>
							<Dropdown v-model="plantilla.id_moneda" :options="lkpMonedas" optionLabel="moneda" optionValue="id_moneda" placeholder="Seleccionar..." />                            
						</div>   
						<div class="p-field p-col-12">
							<label class="p-mr-1 p-as-center" for="plantilla_notif">Plantilla de Notificación</label>
							<Dropdown v-model="plantilla.id_notificacion_plantilla" :options="lkpPlantillasNotif" optionLabel="plantilla" optionValue="id_notificacion_plantilla" placeholder="Seleccionar..." />                            
						</div>                       
						<div class="p-field p-col-12">
							<label class="p-mr-1 p-as-center" for="referencia">Referencia</label>
							<InputText id="plantilla" v-model="plantilla.referencia" :maxlength="100" />							
						</div>  
					</div>                                                                                                              
					<Toolbar>
						<template #left>						
							<div class="p-inputgroup p-m-1">
								<label class="p-mr-1 p-as-center" for="producto">Producto</label>
								<InputText size="100" id="codigo" type="text" v-model="filtroProducto" v-on:keyup.enter="buscarProducto"/>				
								<Button icon="pi pi-search" @click="buscarProducto"/>							
							</div>
						</template>
					</Toolbar>									
					<div :style="{height: '425px'}">
					<DataTable ref="dt" :value="plantilla.detalle" class="p-datatable-sm" editMode="cell" @cell-edit-complete="onCellEditComplete" v-model:selection="selectedProductoPrecio" dataKey="id_mov_plantilla_det" 
					:paginator="false" 
					:scrollable="true"
					:loading="loadingGrid">
						<Column field="codigo" header="Código" headerStyle="width: 5%; text-align: left" bodyStyle="text-align: left">
						</Column>	
						<Column field="producto" header="Producto" headerStyle="width: 10%; text-align: left" bodyStyle="text-align: left">
						</Column>		
						<Column field="id_moneda" header="Moneda" headerStyle="width: 5%; text-align: right" bodyStyle="text-align: center">
							<template #body="{data}">
								{{ data.id_moneda }}
							</template>
							<!-- <template #editor="slotProps">
								<Dropdown v-model="slotProps.data.id_moneda" :options="lkpMonedas" optionLabel="id_moneda" optionValue="id_moneda" @update:modelValue="onCellEdit($event, slotProps)" placeholder="Seleccionar..." />                            								               	
							</template>		 -->					
							<template #editor="{ data, field }">
								<Dropdown v-model="data[field]" :options="lkpMonedas" optionLabel="id_moneda" optionValue="id_moneda" placeholder="Seleccionar..." />                            								               	
							</template>		
						</Column>												
						<Column field="es_variable" header="Calcular x Area" headerStyle="width: 5%; text-align: right" bodyClass="text-center">
							<template #body="{data, field}">
								<InputSwitch v-model="data[field]" />			
							</template>							
						</Column>	
						<Column field="costo_variable" :header="tituloCostoVariable" headerStyle="width: 5%; text-align: right" bodyStyle="text-align: right" >
							<template #body="{data}">
								<div v-if="data.es_variable">
									{{formatCurrency(data.costo_variable)}}
								</div>
								<div v-else class="p-as-center">
									(No Aplica)
								</div>								
							</template>
							<template #editor="{ data, field }">
								<div v-if="data.es_variable">
									<InputNumber v-model="data[field]" mode="decimal" :minFractionDigits="2"/> 
								</div>  
								<div v-else class="p-as-center">
									(No Aplica)
								</div>	              	
							</template>									
						</Column>	
						<Column field="precio_unitario" header="Monto" headerStyle="width: 5%; text-align: right" bodyStyle="text-align: right">
							<template #body="{data}">
								<div v-if="!data.es_variable">
									{{formatCurrency(data.precio_unitario)}}
								</div>
								<div v-else class="p-as-center">
									(Variable)
								</div>
							</template>
							<template #editor="{ data, field }">
								<div v-if="!data.es_variable">
									<InputNumber v-model="data[field]" mode="decimal" :minFractionDigits="2"/> 
								</div>  
								<div v-else class="p-as-center">
									(Variable)
								</div>								                 	
							</template>									
						</Column>	
						<Column>
							<template #body="slotProps">							
								<Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-mr-2" @click="removerItem(slotProps.data)"/>
							</template>
						</Column>																											
					</DataTable>
					</div>
					<template #footer>
						<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="plantillaDialog = false"/>
						<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="guardarRec"/>						
					</template>
				</Dialog>
				
				<BuscarProducto ref="dlgBuscaProducto" :mostrar="productoDialog" tipo="" mostrar_conversiones="S" @cancelar="cerrarDlgProducto"  @seleccionar="elegirProducto" :filtro="filtroProducto" :key="productoDlgRefresh"/>
				
				<Dialog v-model:visible="deleteDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="appMode==0">Esta seguro que desea eliminar la plantilla? <br><b>[{{ plantilla.plantilla }}]</b>?</span>
                        <span v-if="appMode==1">Esta seguro que desea eliminar el plan de servicio? <br><b>[{{ plantilla.plantilla }}]</b>?</span>                        
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="eliminar" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import Loading from 'vue-loading-overlay';
import InvService from '../service/inv.service';
import {FilterMatchMode} from 'primevue/api';
import BuscarProducto from '../components/BuscarProducto.vue';
import { CURRENT_APP_MODE } from '../service/constants';
// import Checkbox from 'primevue/checkbox';

export default {
	components:
	{
		Loading, BuscarProducto
	},
	data() {
		return {
			appMode: CURRENT_APP_MODE,
            titulo : CURRENT_APP_MODE == 0 ? 'Plantillas de Transacción': 'Planes de Servicio',
            columnaTitulo : CURRENT_APP_MODE == 0 ? 'Plantilla': 'Plan',
			complejo: this.$store.state.auth.currentAppCtx.complejo,
            plantillas: null,
			plantillaDialog: false,   
            monedaBase:  this.$store.state.auth.currentAppCtx.monedaBase,       
            lkpBodegas: this.$store.state.auth.currentAppCtx.Bodegas,            
            lkpMonedas: this.$store.state.auth.currentAppCtx.Monedas,
            lkpMovimientosTipo: this.$store.state.auth.currentAppCtx.tiposMovimientoInv,
            lkpMetodoEnvio: this.$store.state.auth.currentAppCtx.MetodosEnvio,            
            lkpSeries: [],
            lkpCondicionesPago: this.$store.state.auth.currentAppCtx.CondicionesPago,
            lkpPlantillasNotif: [],
			productoDialog: false,
			filtroProducto: '',
			plantillasGrdRefresh: 0,
			productoDlgRefresh: 0,	
            selProducto: {codigo: '', producto: '', unidad_medida: '', descripcion: ''},			
			selectedProductoPrecio: null,
			editingCellRows: {},
			nuevoDialog: false,			
			deleteDialog: false,
			plantilla: {
				hayCambios: false,
				detalle: []
			},
			selected: null,
			loading: false,
			loadingGrid: false,
			filters: {'global': {value: null, matchMode: FilterMatchMode.CONTAINS}},
			submitted: false
		}
	},
	computed: {
		tituloCostoVariable()
		{
			if(this.appMode == 1) //Multidomus
			{
				return `Precio X ${this.complejo.dimensional_area}`;
			} else {
				return 'Costo Variable';
			}
		}
	},
	async mounted() {
		this.loading = true;
        try {
            this.plantillas = await InvService.getPlantillasMov(this.$store.state.auth.currentAppCtx);
            this.$store.state.auth.currentAppCtx.plantillas_mov = this.plantillas;

            this.lkpPlantillasNotif.push({id_notificacion_plantilla: '', plantilla: '(No Asignada)'});
			var plantillasNotif = await InvService.getPlantillasNotificacion(this.$store.state.auth.currentAppCtx);
			Array.prototype.push.apply(this.lkpPlantillasNotif, plantillasNotif);
            this.loading = false;
        } catch(err)
        {
            this.loading = false;
            this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})	         
        }
	},
	methods: {
		formatCurrency(value) {
			if(value)
				return parseFloat(value).toLocaleString('en-US', {minimumFractionDigits: 2});
			return;
		},
		formatCurrencyMoneda(moneda, value) {
			if(value)
				return value.toLocaleString('en-US', {style: 'currency', currency: moneda});
			return;
		},		
		formatPct(value) {
			if(value)
				return parseFloat(value).toLocaleString('en-US', {minimumFractionDigits: 2});
			return;
		},
        isPositiveNumeric(val) {
			if(this.isNumeric(val)){
				let num = parseFloat(val);
				return num >= 0.00;
			} else {
				return false;
			}
		},	
		isNumeric(n) {
			return !isNaN(parseFloat(n)) && isFinite(n);
		},			
		cerrarDlgProducto(){
			this.filtroProducto = '';
			this.productoDialog = false;
		},	
		actualizarItem(plantillaDet) {			
			this.plantilla.detalle.push(plantillaDet)
		},
		elegirProducto(Producto){
			this.productoDialog = false;	
			let plantillaDet =  {
            id_mov_plantilla_det: '',
            id_mov_plantilla: this.plantilla.id_mov_plantilla,
            id_producto_conversion: Producto.id_producto_conversion,
            id_moneda: this.monedaBase.id_moneda,
            cantidad: 1,
            precio_unitario: Producto.precio_unitario,
            pct_descuento: 0.0,
            es_variable: false,
            costo_variable: 0.0,
			codigo: Producto.codigo,
			producto: this.appMode==0?Producto.producto:Producto.descripcion_simple
			};
			this.actualizarItem(plantillaDet);
		},	
		actualizarPlantilla() {
			if(!this.plantilla.hayCambios){
				return;
			}
			
			this.loading = true;
			InvService.registrarPlantillaMov(this.$store.state.auth.currentAppCtx, this.plantilla).then(() => {								
				this.lista.hayCambios = false;
				this.loading = false;
			}).catch(data => {
				this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				this.loading = false;
			})			
		},
		async guardarRec(){		
			this.loading = true;
			try {
				var data = await InvService.registrarPlantillaMov(this.$store.state.auth.currentAppCtx, this.plantilla);

				if(this.plantilla.es_nuevo) {
					this.plantilla.id_mov_plantilla = data.id_mov_plantilla;
					this.plantillas.push(JSON.parse(JSON.stringify(this.plantilla)));
				} else {					
					for (var i =0; i < this.plantillas.length; i++) {
						if (this.plantillas[i].id_mov_plantilla == this.plantilla.id_mov_plantilla) {
							this.plantillas[i] = JSON.parse(JSON.stringify(this.plantilla));							
							break;
						}
					}					
				}

				this.plantilla = {};
				this.plantillasGrdRefresh++;
				this.loading = false;

				this.plantillaDialog = false;	
			} catch (error) {
				this.$toast.add({severity:'error', summary: 'Error', detail: error, life: 3000})
				this.submitted = true;
				this.loading = false;
			}
		},
		removerItem(Item) {
			this.loadingGrid = true;
			InvService.eliminarPlantillaMovDet(this.$store.state.auth.currentAppCtx, Item).then(() => {
				this.plantilla.detalle = this.plantilla.detalle.filter(val => val.id_mov_plantilla_det !== Item.id_mov_plantilla_det);
				this.loadingGrid = false;
			}
			).catch(
				data => {
					this.loadingGrid = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}
			)
		},			
		openNew() {
            try {
                this.plantilla = {
                    id_mov_plantilla: '',
                    id_empresa: this.$store.state.auth.currentAppCtx.id_referencia,
                    id_bodega: this.lkpBodegas[0].id_bodega, 
                    id_movimiento_tipo: this.lkpMovimientosTipo[0].id_movimiento_tipo,
                    id_movimiento_serie: null,
                    id_condicion_pago: this.lkpCondicionesPago[0].id_condicion_pago,
                    id_origen: '',
                    id_motivo: '',
                    id_metodo_envio: null, //this.lkpMetodoEnvio[0].id_metodo_envio,
                    id_moneda: this.monedaBase.id_moneda,
                    id_empleado: '',
                    plantilla: '',
                    referencia: '',
                    pct_descuento: 0.0,
                    descuento: 0.0,
                    pct_comision: 0.0,
                    id_notificacion_plantilla: '',
					es_nuevo: true,
                    detalle: []
                };
                this.cargarSeries();

                this.submitted = false;
                this.plantillaDialog = true;
            } catch(err) {
					this.loading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})
            }
		},
		async edit(registro) {
			try {
				this.loading = true;

				this.cargarSeries();		
				
				this.plantilla = await InvService.getPlantillaMov(this.$store.state.auth.currentAppCtx, registro.id_mov_plantilla);			
	
				this.plantillaDialog = true;
				this.loading = false;
			} catch(err) {
				this.plantillaDialog = false;
				this.plantilla = {};
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})
			}
		},
		confirmDelete(registro) {
			this.plantilla = registro;
			this.deleteDialog = true;
		},
		eliminar() {
			this.loading = true;
			InvService.eliminarPlantillaMov(this.$store.state.auth.currentAppCtx, this.plantilla).then(() => {
					this.plantillas = this.plantillas.filter(val => val.id_mov_plantilla !== this.plantilla.id_mov_plantilla);
					this.deleteDialog = false;
					this.plantilla = {};
					this.loading = false;

                    var msg = 'La plantilla ha sido eliminada';
                    if (this.appMode == 1) // Multidomus
                    {
                        msg = 'El plan de servicio ha sido eliminado';
                    }   
                    this.$toast.add({severity:'success', summary: 'Exito', detail: msg, life: 3000})                 
			}
			).catch(
				data => {
					this.deleteProductDialog = false;
					this.loading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}
			)
		},
        onCellEditComplete(event) {
            let { data, newValue, field } = event;
            
			switch (field) {
                case 'id_moneda':
					data[field] = newValue;		
					break;					
                case 'costo_variable':		
					if (this.isPositiveNumeric(newValue)){ 
						data[field] = newValue; 
					}
					else
						event.preventDefault();
					break;	
				case 'precio_unitario':		
					if (this.isPositiveNumeric(newValue)){ 
						data[field] = newValue; 
					}
					else
						event.preventDefault();
					break;								
                // default:
                //     if (newValue.trim().length > 0)
                //         data[field] = newValue;
                //     else
                //         event.preventDefault();
                // break;
            }
		},	
        onCellEdit(newValue, props) {
            if (!this.editingCellRows[props.index]) {
                this.editingCellRows[props.index] = {...props.data};
            }

            this.editingCellRows[props.index][props.column.props.field] = newValue;
        },	
		buscarProducto()
		{
			// AppCtx, filtro, mostrarConversiones, tipoProducto, idLista, mostrarMateriaPrima
			InvService.buscaProductos(this.$store.state.auth.currentAppCtx, this.filtroProducto, 'S', '', '', 'N').then(data => 
			{
				if(data.length == 1){
					data[0].mostrar = 'S';
					this.elegirProducto(data[0]);	
				} else {
					this.$refs.dlgBuscaProducto.mostrarResultadosBusqueda(this.filtroProducto, data);									
					this.productoDialog = true;
				}
				this.productoDialogLoading = false;
			}	
			).catch(
				data => {
				this.productoDialogLoading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
			}				
			);				
		},		
        async cargarSeries(){
            try {
                this.loading = true;
                var tipos = await this.lkpMovimientosTipo.filter((t)=>{return t.id_movimiento_tipo == this.plantilla.id_movimiento_tipo});
                
                if(tipos.length > 0) {
                    this.lkpSeries = await InvService.getSeriesTipoMovimiento(this.$store.state.auth.currentAppCtx, tipos[0].id_movimiento_tipo_cat);
                    if(this.lkpSeries.length > 0){
                        this.plantilla.id_movimiento_serie = this.lkpSeries[0].id_movimiento_serie;
                    }
                } else {
                    this.lkpSeries.push({id_movimiento_serie: '', serie:'[No hay series definadas]'});
                }

                this.loading = false;
            } catch (err) {
                this.loading = false;
                this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})  
            }
        },
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.products.length; i++) {
				if (this.products[i].id === id) {
					index = i;
					break;
				}
			}

			return index;
		},
		createId() {
			let id = '';
			var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			for ( var i = 0; i < 5; i++ ) {
				id += chars.charAt(Math.floor(Math.random() * chars.length));
			}
			return id;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deleteDialog = true;
		},
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					&:last-child {
						text-align: center;
					}

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
						display: inline-block;
						width: 60%;
					}

					.p-rating {
						display: inline-block;
					}
				}
			}

			.p-datatable-tbody > tr.p-rowgroup-footer{
				display: flex;
			}
		}
	}
}

::v-deep(.p-toolbar) {
	flex-wrap: wrap;
	.p-button {
		margin-bottom: .25rem;
	}
}
</style>
